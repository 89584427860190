<Page name="home">
    <!-- Top Navbar -->
    <Navbar sliding={false} large>
        <NavLeft>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left"/>
        </NavLeft>
        <NavTitle sliding> Carpet Company</NavTitle>
        <NavTitleLarge> Carpet Company</NavTitleLarge>
    </Navbar>
    <Block strong>
        <Card class="data-table data-table-init">
            <CardHeader>
                <div class="data-table-title">ارتباطات</div>
                <div class="data-table-actions">
                    <Button popupOpen=".demo-popup" raised text="اضافه کردن" on:click={()=>isOpened=true}/>
                </div>
            </CardHeader>
            <CardContent>
                <List>
                    <ListInput label="کلاس" type="select" placeholder="کلاس"
                               value={ClassValue} onInput={(e)=>ClassSelect(e.target.value)}>
                        {#each ClassList as f}
                            {#if `${f.id}` === `${ClassValue}`}
                                <option value={f.id} selected>{f.title}</option>
                            {:else}
                                <option value={f.id}>{f.title}</option>
                            {/if}
                        {/each}
                    </ListInput>
                </List>
            </CardContent>
            <CardFooter>
                <table>
                    <thead>
                    <tr class="text-align-center">
                        <th>اولویت</th>
                        <th>عنوان</th>
                        <th>ارتباط با</th>
                        <th>تعداد</th>
                        <th>زیر رابطه</th>
                        <th>تعدی</th>
                        <th>جابجایی</th>
                        <th>فانکشنال</th>
                        <th>فانکشنال معکوس</th>
                        <th>عملیات</th>
                    </tr>
                    </thead>
                    <tbody class="text-align-center">
                    {#each RelationList as r}
                        <tr>
                            <td>{r.priority}</td>
                            <td>{r.title}</td>
                            <td>{r.rangeidName}</td>
                            <td>{r.cardinality}</td>
                            <td>{r.subpropofName}</td>
                            <td>{setTe(r.istransitive)}</td>
                            <td>{setTe(r.issymetric)}</td>
                            <td>{setTe(r.isfunctional)}</td>
                            <td>{setTe(r.isinversefunctional)}</td>
                            <td>
                                <Segmented raised tag="p">
                                    <Button outline color="yellow" text="ویرایش" on:click={()=>edit(r)}/>
                                    <Button outline color="red" text="حذف" on:click={()=>remove(r.id)}/>
                                </Segmented>
                            </td>
                        </tr>
                    {/each}
                    </tbody>
                </table>
            </CardFooter>
        </Card>
    </Block>

    <Popup class="demo-popup" opened={isOpened} onPopupClosed={() => isOpened = false}>
        <Page>
            <Navbar title="کلاس جدید">
                <NavRight>
                    <Link popupClose>بستن</Link>
                </NavRight>
            </Navbar>
            <Block>
                <Row style="width: 100%">
                    <Col width="100">
                        <center>
                            <Button raised text={value.class.name}/>
                        </center>
                    </Col>
                </Row>
                <List noHairlinesMd>
                    <ListInput label="عنوان" floatingLabel type="text" placeholder="عنوان" clearButton
                               value={value.title} onInput={(e)=>value.title=e.target.value}/>

                    <ListInput label="ارتباط با" type="select" placeholder="ارتباط با"
                               value={value.rangeid}
                               onInput={(e)=>value.rangeid=e.target.value}>
                        {#each ClassList as cl}
                            <option value={cl.id}>{cl.title}</option>
                        {/each}
                    </ListInput>

                    <ListInput label="تعداد" floatingLabel type="text" placeholder="تعداد" clearButton
                               value={value.cardinality} onInput={(e)=>value.cardinality=e.target.value}/>

                    <ListInput label="زیر رابطه" type="select" placeholder="زیر رابطه"
                               value={value.subpropof} onInput={(e)=>value.subpropof=e.target.value}>
                        {#each RelationList as d}
                            <option value={d.id}>{d.title}</option>
                        {/each}
                    </ListInput>

                    <ListItem checked={value.istransitive} checkbox title="تعدی"
                              onChange={(e)=>value.istransitive = e.target.checked}/>
                    <ListItem checked={value.issymetric} checkbox title="جابجایی"
                              onChange={(e)=>value.issymetric = e.target.checked}/>
                    <ListItem checked={value.isfunctional} checkbox title="فانکشنال"
                              onChange={(e)=>value.isfunctional = e.target.checked}/>
                    <ListItem checked={value.isinversefunctional} checkbox title="معکوس"
                              onChange={(e)=>value.isinversefunctional = e.target.checked}/>

                    <ListInput label="اولویت" type="select" placeholder="اولویت"
                               value={value.priority}
                               onInput={(e)=>value.priority=e.target.value}>
                        {#each Priority as p}
                            <option value={p}>{p}</option>
                        {/each}
                    </ListInput>

                </List>
                <Row>
                    <Col width="33"/>
                    <Col width="33">
                        <Button text="ثبت" raised on:click={addRelation}/>
                    </Col>
                    <Col width="33"/>
                </Row>
            </Block>
        </Page>
    </Popup>
</Page>

<script>
    let application = null;

    function setTe(i) {
        if (parseInt(i) === 1) return 'بله';
        return 'خیر';
    }

    import {f7ready, f7} from 'framework7-svelte';
    import {onMount} from 'svelte';
    import {Segmented, TextEditor} from 'framework7-svelte';
    import {Popup, ListInput} from 'framework7-svelte';
    import {Page, Navbar, NavLeft, NavTitle, NavTitleLarge} from 'framework7-svelte';
    import {NavRight, Link, Toolbar, Block, BlockTitle, List, ListItem} from 'framework7-svelte';
    import {Row, Col, Button} from 'framework7-svelte';
    import {CardContent, CardFooter, CardHeader, Card} from 'framework7-svelte';
    import WebServices from '../js/WebServices';

    const tclasses = WebServices.function.tclasses();
    const tobjproperty = WebServices.function.tobjproperty();

    let isOpened = false;

    let Priority = [], ClassList = [], RelationList = [];
    for (let i = 1; i <= 100; i += 1) Priority.push(i);
    Priority = Priority;

    let value = {
        class: {name: '', id: -1}, //domainid -> class balaye title
        title: '',
        cardinality: 0,         //teadad
        rangeid: 0,  //ertebat ba
        subpropof: 0,           //RelationList
        istransitive: false,    //taadi
        issymetric: false,      //jabejaye
        isfunctional: false,
        isinversefunctional: false,
        priority: 0,

        id: 0,
        add: true
    };

    function ClassSelect(id) {
        if (id === 0 && ClassList.length > 0) id = ClassList[0].id;
        value.class.id = id;
        ClassList.forEach((o) => {
            if (parseInt(o.id) === parseInt(id)) value.class.name = o.title;
        });
    }

    let ClassValue = localStorage.getItem('Class');
    localStorage.setItem('Class', '0');


    function remove(id) {
        tobjproperty.Remove({id}, function (state) {
            if (state) load();
        })
    }

    function edit(item) {
        const CheckState = (i) => parseInt(i) === 1;
        value = {
            class: {name: '', id: -1},                                  //domainid -> class balaye title
            title: item.title,
            cardinality: item.cardinality,                              //teadad
            rangeid: item.rangeid,                                      //ertebat ba
            subpropof: item.subpropof,                                  //RelationList
            istransitive: CheckState(item.istransitive),                //taadi
            issymetric: CheckState(item.issymetric),                    //jabejaye
            isfunctional: CheckState(item.isfunctional),                //
            isinversefunctional: CheckState(item.isinversefunctional),  //
            priority: item.priority,

            id: item.id,
            add: false
        };
        isOpened = true;
        ClassSelect(item.domainid);
    }

    function addRelation() {
        const callback = function (s) {
            if (s) {
                load();
                reset(false);
            }
        };
        console.log(value);

        if (value.add) {
            tobjproperty.Add({
                domainid: value.class.id,
                title: value.title,
                cardinality: value.cardinality,
                rangeid: value.rangeid,
                subpropof: value.subpropof,
                istransitive: value.istransitive,
                issymetric: value.issymetric,
                isfunctional: value.isfunctional,
                isinversefunctional: value.isinversefunctional,
                priority: value.priority,
            }, (state) => callback(state));
        } else {
            tobjproperty.Update({
                title: value.title,
                cardinality: value.cardinality,
                rangeid: value.rangeid,
                subpropof: value.subpropof,
                istransitive: value.istransitive,
                issymetric: value.issymetric,
                isfunctional: value.isfunctional,
                isinversefunctional: value.isinversefunctional,
                priority: value.priority,
                id: value.id
            }, (state) => callback(state));
        }
    }

    function load() {

        RelationList = [];
        tobjproperty.Load(function (data) {
            if (data.length > 0) {
                data.forEach((o) => {
                    if (o.subpropof === -1) o.subpropofName = '*';
                    if (o.subpropofName == null) o.subpropofName = '*';
                    RelationList.push(o);
                });
                RelationList = RelationList;
                value.subpropof = data[0].id;
            } else value.subpropof = -1;
        });
    }


    function reset(state = false) {
        value = {
            class: {name: '', id: -1}, //domainid -> class balaye title
            title: '',
            cardinality: 0,         //teadad
            rangeid: 0,  //ertebat ba
            subpropof: 0,           //RelationList

            istransitive: false,    //taadi
            issymetric: false,      //jabejaye
            isfunctional: false,
            isinversefunctional: false,
            priority: 0,

            id: 0,
            add: true
        };
        value = value;
        isOpened = state;
    }

    onMount(() => {
        f7ready((app) => {
            localStorage.setItem('page', 'page-relation');

            application = app;
            reset();
            tclasses.Load((c) => {
                ClassList = c;
                try {
                    ClassValue = parseInt(ClassValue);
                    ClassSelect(ClassValue);
                } catch (e) {

                }

                if (ClassList.length > 0) value.rangeid = ClassList[0].id;
                else value.rangeid = -1;
            });
            load();
        });
    })


</script>