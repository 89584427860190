import Class from '../pages/Class.svelte';
import DataAttributes from '../pages/DataAttributes.svelte';
import Relation from '../pages/Relation.svelte';

import NotFoundPage from '../pages/404.svelte';

const routes = [
    {
        path: '/',
        component: Class,
    }, {
        path: '/data-attributes/',
        component: DataAttributes,
    }, {
        path: '/relation/',
        component: Relation,
    }, {
        path: '(.*)',
        component: NotFoundPage,
    }
];

export default routes;
