import {f7} from "framework7-svelte";

const WebServices = {
    base: () => {
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            return 'http://android.carpetcompany.digihelp.net/REST_API/index.php/';
        } else {
            return 'REST_API/index.php/';
        }
    },
    method: {
        'tclasses': {
            'add': 'tclasses/Add',
            'remove': 'tclasses/Remove',
            'update': 'tclasses/Update',
            'load': 'tclasses/Load'
        },
        'tdataproperty': {
            'add': 'tdataproperty/Add',
            'remove': 'tdataproperty/Remove',
            'update': 'tdataproperty/Update',
            'load': 'tdataproperty/Load'
        },
        'tobjproperty': {
            'add': 'tobjproperty/Add',
            'remove': 'tobjproperty/Remove',
            'update': 'tobjproperty/Update',
            'load': 'tobjproperty/Load'
        }
    },
    function: {
        LoadData: function (request, callback, error = null, param = {}) {
            const url = WebServices.base() + request;
            const resultCallback = (data) => {
                if (data.length === 1) data = data[0];
                if (data.hasOwnProperty('response')) data = data.response;
                if (typeof callback == "function") callback(data);
            };
            console.log(url);
            (async function (address, p = {}) {
                if (typeof f7 != "undefined") {
                    f7.request.post(url, p, function (data, xhr, status) {
                        if (xhr === 200) {
                            resultCallback(data);
                        } else if (typeof error == "function") error(data);
                    }, function (xhr, status) {
                        console.log('error xhr :' + JSON.stringify(xhr));
                        console.log('error status :' + JSON.stringify(status));
                        console.log('error arguments :' + JSON.stringify(arguments));
                        if (typeof error == "function") error({xhr, data: status});
                    }, 'json');
                } else {
                    const response = await fetch(address);
                    if (response.status === 200) {
                        const data = await response.json();
                        resultCallback(data);
                    } else {
                        if (typeof error == "function") error({xhr: response, data: response});
                    }
                }
            })(url, param);
        }, tclasses: function () {
            //title	parent	sameas	diffrentfrom
            const Add = (param, callback) => {
                WebServices.function.LoadData(WebServices.method.tclasses.add, function (json) {
                    if (typeof callback == "function") callback(json.add === true);
                }, null, param);
            }, Load = (callback) => {
                WebServices.function.LoadData(WebServices.method.tclasses.load, function (json) {
                    if (typeof callback == "function") callback(json);
                });
            }, Remove = (param, callback) => {
                WebServices.function.LoadData(WebServices.method.tclasses.remove, function (json) {
                    if (typeof callback == "function") callback(json.remove === true);
                }, null, param);
            }, Update = (param, callback) => {
                WebServices.function.LoadData(WebServices.method.tclasses.update, function (json) {
                    if (typeof callback == "function") callback(json.update === true);
                }, null, param);
            };
            return {Add, Remove, Update, Load};
        },
        tdataproperty: function () {
            //cid, title, dtype, dconstraint, priority
            const Add = (param, callback) => {
                WebServices.function.LoadData(WebServices.method.tdataproperty.add, function (json) {
                    if (typeof callback == "function") callback(json.add === true);
                }, null, param);
            }, Load = (callback) => {
                WebServices.function.LoadData(WebServices.method.tdataproperty.load, function (json) {
                    if (typeof callback == "function") callback(json);
                });
            }, Remove = (param, callback) => {
                WebServices.function.LoadData(WebServices.method.tdataproperty.remove, function (json) {
                    if (typeof callback == "function") callback(json.remove === true);
                }, null, param);
            }, Update = (param, callback) => {
                WebServices.function.LoadData(WebServices.method.tdataproperty.update, function (json) {
                    if (typeof callback == "function") callback(json.update === true);
                }, null, param);
            };
            return {Add, Remove, Update, Load};
        }, tobjproperty: function () {
            const Add = (param, callback) => {
                WebServices.function.LoadData(WebServices.method.tobjproperty.add, function (json) {
                    console.log(json);
                    if (typeof callback == "function") callback(json.add === true);
                }, null, param);
            }, Load = (callback) => {
                WebServices.function.LoadData(WebServices.method.tobjproperty.load, function (json) {
                    if (typeof callback == "function") callback(json);
                });
            }, Remove = (param, callback) => {
                WebServices.function.LoadData(WebServices.method.tobjproperty.remove, function (json) {
                    if (typeof callback == "function") callback(json.remove === true);
                }, null, param);
            }, Update = (param, callback) => {
                WebServices.function.LoadData(WebServices.method.tobjproperty.update, function (json) {
                    console.log({json});
                    if (typeof callback == "function") callback(json.update === true);
                }, null, param);
            };
            return {Add, Remove, Update, Load};
        }
    }
};

export default WebServices;