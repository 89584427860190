<Page name="home">
    <!-- Top Navbar -->
    <Navbar sliding={false} large>
        <NavLeft>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left"/>
        </NavLeft>
        <NavTitle sliding> Carpet Company</NavTitle>
        <NavTitleLarge> Carpet Company</NavTitleLarge>
    </Navbar>
    <Block strong>

        <Card class="data-table data-table-init">
            <CardHeader>
                <div class="data-table-title">صفات داده ای</div>
                <div class="data-table-actions">
                    <Button popupOpen=".demo-popup" raised text="صفت جدید" on:click={()=>isOpened=true}/>
                </div>
            </CardHeader>
            <CardContent>
                <List>
                    <ListInput label="کلاس" type="select" placeholder="کلاس"
                               value={ClassValue} onInput={(e)=>ClassSelect(e.target.value)}>
                        {#each ClassList as f}
                            {#if `${f.id}` === ClassValue}
                                <option value={f.id} selected>{f.title}</option>
                            {:else}
                                <option value={f.id}>{f.title}</option>
                            {/if}
                        {/each}
                    </ListInput>
                </List>
            </CardContent>
            <CardFooter>
                <table>
                    <thead>
                    <tr class="text-align-center">
                        <th>کلاس</th>
                        <th>عنوان</th>
                        <th>نوع داده</th>
                        <th>اولویت</th>
                        <th>محدودیت مقدار</th>
                        <th>عملیات</th>
                    </tr>
                    </thead>
                    <tbody class="text-align-center">
                    {#each AttributesList as $i}
                        <tr>
                            <td>{$i.ClassName}</td>
                            <td>{$i.title}</td>
                            <td>{$i.dtype}</td>
                            <td>{$i.priority}</td>
                            <td>{$i.dconstraint}</td>
                            <td>
                                <Segmented raised tag="p">
                                    <Button outline color="yellow" text="ویرایش" on:click={()=>edit($i)}/>
                                    <Button outline color="red" text="حذف" on:click={()=>remove($i.id)}/>
                                </Segmented>
                            </td>
                        </tr>
                    {/each}
                    </tbody>
                </table>
            </CardFooter>
        </Card>
    </Block>

    <Popup class="demo-popup" opened={isOpened} onPopupClosed={() => isOpened = false}>
        <Page>
            <Navbar title="کلاس جدید">
                <NavRight>
                    <Link popupClose>بستن</Link>
                </NavRight>
            </Navbar>
            <Block>
                <Row style="width: 100%">
                    <Col width="100">
                        <center>
                            <Button raised text={value.class.name}/>
                        </center>
                    </Col>
                </Row>
                <List noHairlinesMd>
                    <ListInput label="عنوان" floatingLabel type="text" placeholder="عنوان" clearButton
                               value={value.title} onInput={(e)=>value.title=e.target.value}/>

                    <ListInput label="نوع داده" type="select" placeholder="نوع داده"
                               value={value.dataType}
                               onInput={(e)=>value.dataType=e.target.value}>
                        {#each DataTypeList as type}
                            <option value={type}>{type}</option>
                        {/each}
                    </ListInput>
                    <ListInput label="اولویت" type="select" placeholder="اولویت"
                               value={value.priority}
                               onInput={(e)=>value.priority=e.target.value}>
                        {#each Prioraty as p}
                            <option value={p}>{p}</option>
                        {/each}
                    </ListInput>
                    <ListItem style="width: 100%">
                        <TextEditor
                                label="محدودیت مقدار"
                                value={value.dconstraint}
                                placeholder="محدودیت مقدار"
                                mode="keyboard-toolbar"
                                onTextEditorChange={(v) => value.dconstraint = v}
                                style="width: 100%;--f7-text-editor-height: 150px"/>
                    </ListItem>
                </List>
                <Row>
                    <Col width="33"/>
                    <Col width="33">
                        <Button text="ثبت" raised on:click={addAttribute}/>
                    </Col>
                    <Col width="33"/>
                </Row>
            </Block>
        </Page>
    </Popup>

</Page>

<script>
    let application = null;


    import {f7ready, f7} from 'framework7-svelte';
    import {onMount} from 'svelte';
    import {Segmented, TextEditor} from 'framework7-svelte';
    import {Popup, ListInput} from 'framework7-svelte';
    import {Page, Navbar, NavLeft, NavTitle, NavTitleLarge} from 'framework7-svelte';
    import {NavRight, Link, Toolbar, Block, BlockTitle, List, ListItem} from 'framework7-svelte';
    import {Row, Col, Button} from 'framework7-svelte';
    import {CardContent, CardFooter, CardHeader, Card} from 'framework7-svelte';
    import WebServices from '../js/WebServices';

    const tclasses = WebServices.function.tclasses();
    const tdataproperty = WebServices.function.tdataproperty();

    let isOpened = false;
    let Prioraty = [];
    for (let i = 1; i <= 100; i += 1) Prioraty.push(i);
    Prioraty = Prioraty;

    let ClassList = [], DataTypeList = ['int', 'float', 'string', 'boolean'];
    let ClassValue = localStorage.getItem('Class');
    localStorage.setItem('Class', '0');

    let AttributesList = [], value = {
        class: {id: -1, name: ''},
        title: '',
        dataType: '',
        prioraty: 0,
        dconstraint: '',
        add: true
    };


    function reset(state = false) {
        value = {
            class: {id: -1, name: ''},
            title: '',
            dataType: '',
            priority: 0,
            dconstraint: '',
            add: true
        };
        value = value;
        isOpened = state;
    }


    function addAttribute() {
        const callback = function (s) {
            if (s) {
                Load();
                reset();
                isOpened = false;
            }
        };

        if (value.add) {
            tdataproperty.Add({
                cid: value.class.id,
                title: value.title,
                dtype: value.dataType,
                priority: value.priority,
                dconstraint: value.dconstraint
            }, (state) => callback(state));
        } else {
            tdataproperty.Update({
                title: value.title,
                dtype: value.dataType,
                priority: value.priority,
                dconstraint: value.dconstraint,
                'id': value.id
            }, (state) => callback(state));
        }
    }


    function edit($item) {
        value = {
            class: {id: -1, name: ''},
            title: $item.title,
            dataType: $item.dtype,
            priority: $item.priority,
            dconstraint: $item.dconstraint,
            id: $item.id,
            add: false
        };
        ClassSelect($item.cid);
        value = value;
        isOpened = true;
    }

    function remove(id) {
        tdataproperty.Remove({id}, function (status) {
            if (status) Load();
        });
    }

    function ClassSelect(id) {
        if (id === 0 && ClassList.length > 0) id = ClassList[0].id;
        value.class.id = id;
        ClassList.forEach((o) => {
            if (parseInt(o.id) === parseInt(id)) value.class.name = o.title;
        });
        value = value;
    }

    function Load() {
        tdataproperty.Load((data) => {
            AttributesList = data;
        });
    }


    onMount(() => {
        f7ready((app) => {
            localStorage.setItem('page', 'page-attribute');

            application = app;
            reset();
            tclasses.Load((data) => {
                ClassList = data;
                try {
                    ClassValue = parseInt(ClassValue);
                    ClassSelect(ClassValue);
                } catch (e) {

                }
            });

            Load();

        })
    });

</script>