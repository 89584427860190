<App params={ f7params }>

    <!-- Left panel with cover effect when hidden -->
    <Panel left cover themeDark visibleBreakpoint={960}>
        <View>
            <Page>
                <Navbar title="Left Panel"/>
                <BlockTitle>پایگاه دانش</BlockTitle>
                <List>
                    <ListItem link="/" view=".view-main" panelClose title="کلاسها"/>
                    <ListItem link="/data-attributes/" view=".view-main" panelClose title="صفات داده ای"/>
                    <ListItem link="/relation/" view=".view-main" panelClose title="ارتباطات"/>
                </List>
            </Page>
        </View>
    </Panel>


    <!-- Right panel with reveal effect-->
    <Panel right reveal themeDark>
        <View>
            <Page>
                <Navbar title="Right Panel"/>
                <Block>Right panel content goes here</Block>
            </Page>
        </View>
    </Panel>


    <!-- Your main view, should have "view-main" class -->
    {#if page === 'page-attribute'}
        <View main class="safe-areas" url="/data-attributes/"/>
    {:else if page === 'page-relation'}
        <View main class="safe-areas" url="/relation/"/>
    {:else}
        <View main class="safe-areas" url="/"/>
    {/if}

        <!-- Popup -->
    <Popup id="my-popup">
        <View>
            <Page>
                <Navbar title="Popup">
                    <NavRight>
                        <Link popupClose>Close</Link>
                    </NavRight>
                </Navbar>
                <Block>
                    <p>Popup content goes here.</p>
                </Block>
            </Page>
        </View>
    </Popup>

    <LoginScreen id="my-login-screen">
        <View>
            <Page loginScreen>
                <LoginScreenTitle>Login</LoginScreenTitle>
                <List form>
                    <ListInput
                            type="text"
                            name="username"
                            placeholder="Your username"
                            value={username}
                            onInput={(e) => username = e.target.value}
                    />
                    <ListInput
                            type="password"
                            name="password"
                            placeholder="Your password"
                            value={password}
                            onInput={(e) => password = e.target.value}
                    />
                </List>
                <List>
                    <ListButton title="Sign In" onClick={() => alertLoginData()}/>
                </List>
                <BlockFooter>
                    Some text about login information.<br/>Click "Sign In" to close Login Screen
                </BlockFooter>
            </Page>
        </View>
    </LoginScreen>
</App>
<script>
    import {onMount} from 'svelte';

    import {
        f7,
        f7ready,
        App,
        Panel,
        Views,
        View,
        Popup,
        Page,
        Navbar,
        Toolbar,
        NavRight,
        Link,
        Block,
        BlockTitle,
        LoginScreen,
        LoginScreenTitle,
        List,
        ListItem,
        ListInput,
        ListButton,
        BlockFooter
    } from 'framework7-svelte';


    import routes from '../js/routes';

    //
    //
    //
    let page = localStorage.getItem('page');

    page = `${page}`;

    // Framework7 Parameters
    let f7params = {
        name: ' Carpet Company', // App name
        theme: 'auto', // Automatic theme detection


        // App routes
        routes: routes,
    };
    // Login screen demo data
    let username = '';
    let password = '';

    function alertLoginData() {
        f7.dialog.alert('Username: ' + username + '<br>Password: ' + password, () => {
            f7.loginScreen.close();
        });
    }


    onMount(() => {
        f7ready(() => {

            // Call F7 APIs here
        });
    })
</script>