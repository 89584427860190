<Page name="home">
    <!-- Top Navbar -->
    <Navbar sliding={false} large>
        <NavLeft>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left"/>
        </NavLeft>
        <NavTitle sliding> Carpet Company</NavTitle>
        <NavTitleLarge> Carpet Company</NavTitleLarge>
    </Navbar>
    <Block strong>
        <Card class="data-table data-table-init">
            <CardHeader>
                <div class="data-table-title">کلاس ها</div>
                <div class="data-table-actions">
                    <Button popupOpen=".demo-popup" raised text="اضافه کردن کلاس جدید" on:click={()=>reset(true)}/>
                </div>
            </CardHeader>
            <CardContent>
                <table>
                    <thead>
                    <tr class="text-align-center">
                        <th>شناسه</th>
                        <th>عنوان</th>
                        <th>پدر</th>
                        <th>مشابه با</th>
                        <th>متفاوت از</th>
                        <th>عملیات</th>
                    </tr>
                    </thead>
                    <tbody class="text-align-center">
                    {#each ClassList as $i}
                        {#if $i.id !== -1}
                            <tr>
                                <td>{$i.id}</td>
                                <td>{$i.title}</td>
                                <td>{$i.parentName}</td>
                                <td>{$i.sameasName}</td>
                                <td>{$i.diffrentfromName}</td>
                                <td>
                                    <Segmented raised tag="p">
                                        <Button outline color="yellow" text="ویرایش" on:click={()=>edit($i)}/>
                                        <Button outline color="red" text="حذف" on:click={()=>remove($i.id)}/>
                                    </Segmented>
                                    <Segmented raised tag="p">
                                        <Button text="صفات" on:click={()=>gotoPage('attribute', $i)}/>
                                        <Button text="ارتباط" on:click={()=>gotoPage('relation', $i)}/>
                                    </Segmented>
                                </td>
                            </tr>
                        {/if}
                    {/each}
                    </tbody>
                </table>
            </CardContent>
        </Card>
    </Block>

    <Popup class="demo-popup" opened={isOpened} onPopupClosed={() => isOpened = false}>
        <Page>
            <Navbar title="کلاس جدید">
                <NavRight>
                    <Link popupClose>بستن</Link>
                </NavRight>
            </Navbar>
            <Block>

                <List noHairlinesMd>
                    <ListInput label="عنوان" floatingLabel type="text" placeholder="عنوان" clearButton
                               value={title} onInput={(e)=>title=e.target.value}/>

                    <ListInput label="پدر" type="select" placeholder="پدر"
                               value={value.father}
                               onInput={(e)=>SelectFather(e.target.value)}>
                        {#each ClassList as f}
                            <option value={f.id}>{f.title}</option>
                        {/each}
                    </ListInput>

                    <ListInput label="مشابه با" type="select" placeholder="مشابه با"
                               value={value.sameas}
                               onInput={(e)=>SelectSame(e.target.value)}>
                        {#each ClassList as same}
                            <option value={same.id}>{same.title}</option>
                        {/each}
                    </ListInput>

                    <ListInput label="متفاوت از" type="select" placeholder="متفاوت از"
                               value={value.diffrentfrom}
                               onInput={(e)=>SelectDif(e.target.value)}>
                        {#each ClassList as def}
                            <option value={def.id}>{def.title}</option>
                        {/each}
                    </ListInput>
                </List>
                <Row>
                    <Col width="33"/>
                    <Col width="33">
                        <Button text="ثبت" raised on:click={addClass}/>
                    </Col>
                    <Col width="33"/>
                </Row>
            </Block>
        </Page>
    </Popup>

</Page>

<script>
    let application = null;

    import {f7ready, f7} from 'framework7-svelte';
    import {onMount} from 'svelte';
    import {Segmented} from 'framework7-svelte';
    import {Popup, ListInput} from 'framework7-svelte';
    import {Page, Navbar, NavLeft, NavTitle, NavTitleLarge} from 'framework7-svelte';
    import {NavRight, Link, Toolbar, Block, BlockTitle, List, ListItem} from 'framework7-svelte';
    import {Row, Col, Button} from 'framework7-svelte';
    import {CardContent, CardFooter, CardHeader, Card} from 'framework7-svelte';
    import WebServices from '../js/WebServices';


    let isOpened = false;
    let ClassList = [], title = '', value = {
        'father': -1,
        'sameas': -1,
        'diffrentfrom': -1,
        add: true
    };

    function reset(state = false) {
        title = '';
        value = {
            'father': -1,
            'sameas': -1,
            'diffrentfrom': -1,
            add: true,
            id: -1
        };
        value = value;
        isOpened = state;
    }

    function SelectFather(id) {
        value.father = id;
    }


    function SelectSame(id) {
        value.sameas = id;
    }


    function SelectDif(id) {
        value.diffrentfrom = id;
    }


    const tclasses = WebServices.function.tclasses();


    function gotoPage(link, item) {
        if (link === 'attribute') {
            localStorage.setItem("Class", item.id);
            f7.views.main.router.navigate(`/data-attributes/`, {
                animate: true,
                transition: 'f7-fade'
            });
        }

        if (link === 'relation') {
            localStorage.setItem("Class", item.id);
            f7.views.main.router.navigate(`/relation/`, {
                animate: true,
                transition: 'f7-fade'
            });
        }
    }

    function edit(item) {
        title = item.title;
        value = {
            'father': item.parent,
            'sameas': item.sameas,
            'diffrentfrom': item.diffrentfrom,
            add: false,
            id: item.id
        };
        value = value;
        isOpened = true;
    }

    function remove(id) {
        tclasses.Remove({id}, function (status) {
            if (status) Load();
        });
    }


    function Load() {
        ClassList = [];
        tclasses.Load(function (data) {
            ClassList.push({id: -1, title: 'بدون عنوان'});
            if (data.length > 0) {
                data.forEach((o) => {
                    if (o.parentName == null) o.parentName = '*';
                    if (o.sameasName == null) o.sameasName = '*';
                    if (o.diffrentfromName == null) o.diffrentfromName = '*';
                    ClassList.push(o)
                });
            }
            ClassList = ClassList;
        });
    }


    function addClass() {
        const callback = function (s) {
            if (s) {
                Load();
                reset();
                isOpened = false;
            }
        };

        if (value.add) {
            tclasses.Add({
                'title': title,
                'parent': value.father,
                'sameas': value.sameas,
                'diffrentfrom': value.diffrentfrom
            }, (state) => callback(state));
        } else {
            tclasses.Update({
                'title': title,
                'parent': value.father,
                'sameas': value.sameas,
                'diffrentfrom': value.diffrentfrom,
                'id': value.id
            }, (state) => callback(state));
        }
    }


    onMount(() => {
        f7ready((app) => {
            localStorage.setItem('page','page-class');
            application = app;
            reset();
            Load();
        })
    });

</script>